/* You can add global styles to this file, and also import other style files */
/* Importing Bootstrap SCSS file. */
@import '~bootstrap/scss/bootstrap';

html, body { height: 100%; }
body { 
    margin: 0; 
    font-family: 'roboto', "Helvetica Neue", sans-serif; 
    // font-family: 'lato';
    // font-family: 'Montserrat';
    font-family: 'Raleway';
    // font-family: 'roboto' !important;
}

.success-snackbar {
    background-color: #28a745;
}

.error-snackbar {
    background-color: #dc3545;    
}

.mat-simple-snackbar {
    font-size: 16px;
    color: black;
}

.mat-simple-snackbar-action  {
    color: black;
}
